<template>
  <div>
    <v-row class="mt-1" justify="center" dense>
      <v-col cols="6" md="3">
        <v-text-field
          :value="tipo_doc_items[fact_data.tipo_doc - 1].text"
          label="Tipo de documento"
          readonly
          outlined
          dense
        />
      </v-col>
      <v-col cols="6" md="3">
        <v-text-field
          :value="
            serie_doc_items[fact_data.tipo_doc - 1][fact_data.serie_doc - 1]
              .text
          "
          placeholder="Serie del documento"
          label="Serie"
          readonly
          outlined
          dense
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          label="Documento del cliente"
          v-model="fact_data.client_doc"
          ref="client_doc"
          outlined
          readonly
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="fact_data.client_name"
          label="Cliente"
          outlined
          readonly
          dense
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          label="Dirección del cliente"
          v-model="fact_data.client_direccion"
          outlined
          dense
          readonly
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" md="4">
        <v-text-field
          label="Departamento"
          v-model="fact_data.departamentoText"
          outlined
          readonly
          dense
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          label="Provincia"
          v-model="fact_data.provinciaText"
          outlined
          readonly
          dense
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          label="Distrito"
          v-model="fact_data.distritoText"
          outlined
          readonly
          dense
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="products"
          item-key="id"
          class="elevation-1"
          ref="productsTable"
          :hide-default-footer="true"
          disable-pagination
          sort-by="code"
          no-data-text="No se seleccionaron productos"
          :dense="products.length > 8"
        >
          <template v-slot:item.is_free="{ item }">
            <v-simple-checkbox
              color="primary"
              v-model="item.is_free"
              :ripple="false"
              disabled
            ></v-simple-checkbox>
          </template>
          <template v-slot:item.code="{ item }">
            <span
              :class="
                item.stock < item.quantity ? 'warning--text' : 'success--text'
              "
            >
              {{ item.code }}</span
            >
          </template>

          <template v-slot:item.total_price="{ item }">
            {{
              getTotalPrice(
                item.unit_price,
                item.quantity,
                item.discount,
                item.is_free
              )
            }}
          </template>
          <template v-slot:no-data>
            <span class="error--text text--lighten-2">
              No se seleccionaron productos
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row class="mb-1">
      <v-spacer />
      <v-col cols="12" md="2" align="right">
        <v-text-field
          label="Total"
          class="text-h6"
          :prefix="fact_data.currency === 'SOLES' ? 'S/.' : '$'"
          v-model="fact_data.total_ammount"
          hide-details="true"
          outlined
          readonly
          dense
        />
      </v-col>
      <!-- <v-col align="right" class="text-h6">
        Total:
        {{ fact_data.currency === 'SOLES' ? 'S/.' : '$' }}
        {{ fact_data.total_ammount }}
      </v-col> -->
    </v-row>
    <v-btn color="primary" @click="goNext">
      Finalizar
    </v-btn>
    <v-btn text @click="goPrev">
      Atras
    </v-btn>
  </div>
</template>

<script>
export default {
  props: { fact_data: Object, products: Array },
  data: () => ({
    tipo_doc_items: [
      { text: 'Boleta', value: 1 },
      { text: 'Factura', value: 2 }
    ],
    serie_doc_items: [
      [
        { text: '001', value: 1 },
        { text: '002', value: 2 },
        { text: '003', value: 3 }
      ],
      [
        { text: '001', value: 1 },
        { text: '002', value: 2 },
        { text: '003', value: 3 }
      ]
    ],
    headers: [
      {
        text: 'Gratuito',
        align: 'center',
        value: 'is_free',
        divider: true
      },
      {
        text: 'Código',
        align: 'start',
        sortable: true,
        value: 'code',
        divider: true
      },
      {
        text: 'Descripción',
        value: 'description',
        divider: true,
        filtereable: false
      },
      {
        text: 'Stock',
        sortable: false,
        value: 'stock',
        divider: true,
        align: 'center'
      },
      {
        text: 'Cantidad',
        sortable: false,
        value: 'quantity',
        divider: true,
        align: 'center'
      },
      {
        text: 'Precio Unitario',
        filtereable: false,
        sortable: false,
        value: 'unit_price',
        divider: true,
        align: 'center'
      },
      {
        text: 'Descuento (%)',
        filtereable: false,
        sortable: false,
        value: 'discount',
        divider: true,
        align: 'center'
      },
      {
        text: 'Precio Total',
        filtereable: false,
        sortable: false,
        value: 'total_price',
        divider: true,
        align: 'center'
      }
    ]
  }),
  methods: {
    goPrev() {
      this.$emit('prevStage')
    },
    goNext() {
      this.$emit('finalizeCreation')
    }
  }
}
</script>

<style scoped>
.v-input >>> .v-label {
  /* font-size: 1em; */
  text-transform: uppercase;
}
</style>
