<template>
  <div class="home">
    <v-container class="my-6">
      <div>
        <v-row>
          <v-col>
            <div class="text-h5 text-md-h3">Crear Factura</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-stepper v-model="facturacion_stage" vertical>
              <v-stepper-step
                editable
                :complete="facturacion_stage > 1"
                step="1"
                :rules="[() => dataStageValid]"
              >
                Datos del Cliente
                <small v-if="!dataStageValid">Faltan completar Datos</small>
              </v-stepper-step>

              <v-stepper-content step="1">
                <data-stage
                  v-on:dataStageCompleted="dataCompleted"
                  v-on:dataStageFailed="dataStageValid = false"
                  v-on:prevStage="facturacion_stage--"
                  ref="dataStageComp"
                />
              </v-stepper-content>

              <v-stepper-step
                :complete="facturacion_stage > 2"
                step="2"
                :rules="[() => prodStageValid]"
              >
                Productos
                <small v-if="!prodStageValid">Faltan completar Datos</small>
              </v-stepper-step>

              <v-stepper-content step="2">
                <product-stage
                  v-on:prodStageCompleted="prodCompleted"
                  v-on:prodStageFailed="prodFailed"
                  v-on:prevStage="facturacion_stage--"
                />
              </v-stepper-content>

              <v-stepper-step step="3">
                Resumen del documento
              </v-stepper-step>
              <v-stepper-content step="3">
                <doc-resume
                  v-if="facturacion_stage === 3"
                  :fact_data="fact_data"
                  :products="products"
                  v-on:finalizeCreation="sendDocument"
                  v-on:prevStage="facturacion_stage--"
                />
              </v-stepper-content>
            </v-stepper>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import DataStage from '../components/facturation/DataStage.vue'
import DocResume from '../components/facturation/DocResume.vue'
import ProductStage from '../components/facturation/ProductStage.vue'
export default {
  components: { DataStage, ProductStage, DocResume },
  data: () => ({
    facturacion_stage: 1,
    dataStageValid: true,
    prodStageValid: true,
    fact_data: '',
    products: []
  }),
  watch: {
    facturacion_stage(val) {
      console.log('new Value for stage', val == 2, !this.fact_data)
      if (val == 2 && !this.fact_data) {
        console.log('Errror message')
        this.dataStageValid = false
      }
    }
  },
  methods: {
    dataCompleted(data) {
      console.log('Data Stage finalized: ', data)
      this.fact_data = data
      this.facturacion_stage++
      this.dataStageValid = true
    },
    prodCompleted(dataStage) {
      console.log(dataStage)
      if (this.dataStageValid === false) {
        this.$showMessage(
          'Tiene que completar los datos del cliente',
          'warning'
        )
        return
      } else {
        Object.assign(this.fact_data, dataStage.prod_data)
        this.products = dataStage.products
        this.facturacion_stage++
        this.prodStageValid = true
      }
    },
    prodFailed(reason) {
      this.$showMessage(reason, 'error')
      this.prodStageValid = false
    },
    sendDocument() {
      console.log('Send Fact', this.fact_data, this.products)
      this.fact_data.remark = ''
      this.$makePostRequest('/api/transactions/store', {
        doc_data: this.fact_data,
        products: this.products
      })
        .then(() => {
          this.$showMessage('Documento guardado con exito', 'success')
          this.$router.push('/facturacion/ver')
        })
        .catch(err => {
          console.log(err)
          this.$showMessage('Hubo un error al guardar el documento', 'error')
        })
    }
  }
}
</script>
