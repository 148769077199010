<template>
  <v-form ref="docDataForm" v-model="docDataValid" lazy-validation>
    <v-row class="mt-1">
      <v-col cols="12" md="6">
        <v-select
          v-model="doc_data_form.tipo_doc"
          :items="tipo_doc_items"
          placeholder="Seleccione el tipo de documento"
          label="Tipo de Documento"
          :rules="[v => !!v || 'Seleccione un tipo de Documento']"
          outlined
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          v-model="doc_data_form.serie_doc"
          :items="serie_doc_items[doc_data_form.tipo_doc - 1]"
          label="Serie"
          placeholder="Seleccione la serie del documento"
          :rules="[v => !!v || 'Seleccione la serie del documento']"
          outlined
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          v-model="doc_data_form.seller_id"
          :items="sellers"
          placeholder="Seleccione un vendedor"
          ref="seller"
          item-text="name"
          item-value="id"
          label="Vendedor"
          :rules="[v => !!v || 'Seleccione un vendedor']"
          outlined
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-menu
          ref="expire_menu"
          v-model="expire_menu"
          :close-on-content-click="false"
          :return-value.sync="doc_data_form.expire_at"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="doc_data_form.expire_at"
              label="Fecha de vencimiento"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="doc_data_form.expire_at"
            no-title
            scrollable
            locale="es-pe"
            @input="$refs.expire_menu.save(doc_data_form.expire_at)"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="8" md="4">
        <!-- <v-text-field
          :label="tipoDocLabel + ' del Cliente'"
          :rules="doc_id_rules[doc_data_form.tipo_doc - 1]"
          :counter="doc_data_form.tipo_doc === 1 ? 8 : 11"
          v-model="doc_data_form.client_doc"
          ref="client_doc"
          outlined
        ></v-text-field> -->
        <v-autocomplete
          v-model="selected_client"
          :items="clients"
          :placeholder="`Escriba el ${tipoDocLabel} o nombre del cliente`"
          label="Buscador de clientes"
          item-text="name"
          item-value="id"
          return-object
          :rules="[
            v =>
              notEmptyObject(v) || 'Escriba el documento o nombre del cliente'
          ]"
          :filter="filterObject"
          ref="clientSearch"
          outlined
        >
          <template v-slot:item="data">
            <template>
              <v-list-item-content>
                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                <v-list-item-subtitle
                  v-html="data.item.ruc"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="4" md="2" class="mt-3">
        <v-btn color="secondary" @click="expandClient">
          <span class="d-flex d-sm-none"> <v-icon>mdi-magnify</v-icon></span>
          <span class="d-none d-sm-block">Buscar Datos</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="!!api.client_data">
      <!-- {{ doc_data_form }} -->
      <v-col cols="12" md="6">
        <v-text-field
          label="Nombre del cliente"
          v-model="clientText"
          outlined
          ref="client_name"
          readonly
          placeholder="Nombre del cliente"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          :label="tipoDocLabel + ' del Cliente'"
          :rules="doc_id_rules[doc_data_form.tipo_doc - 1]"
          :counter="doc_data_form.tipo_doc === 1 ? 8 : 11"
          v-model="doc_data_form.client_doc"
          ref="client_doc"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="12">
        <!-- <v-text-field
          label="Dirección del cliente"
          :rules="[v => !!v || 'Llene este campo']"
          v-model="doc_data_form.client_direccion"
          ref="client_dir"
          outlined
        ></v-text-field> -->
        <v-select
          v-model="selected_address"
          :items="selected_client_data.addresses"
          return-object
          label="Dirección"
          :rules="[v => !!v || 'Seleccione una dirección']"
          :item-text="getFullAddress"
          outlined
        >
        </v-select>
      </v-col>
      <!-- <v-col cols="12" md="4">
        <v-select
          v-model="ubigeo.departamento"
          :items="departamentos"
          return-object
          label="Departamento"
          :rules="[v => !!v || 'Seleccione el Departamento']"
          item-text="nombre_ubigeo"
          item-value="id_ubigeo"
          outlined
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-select
          v-model="ubigeo.provincia"
          :items="provincias[ubigeo.departamento.id_ubigeo]"
          label="Provincia"
          return-object
          :rules="[v => !!v || 'Seleccione la provincia']"
          item-text="nombre_ubigeo"
          item-value="id_ubigeo"
          no-data-text="Primero seleccione un departamento"
          :readonly="!ubigeo.departamento"
          ref="provincia"
          outlined
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-select
          v-model="ubigeo.distrito"
          :items="distritos[ubigeo.provincia.id_ubigeo]"
          label="Distrito"
          return-object
          :rules="[v => !!v || 'Seleccione el distrito']"
          item-text="nombre_ubigeo"
          item-value="id_ubigeo"
          no-data-text="Primero seleccione una provincia"
          :readonly="!ubigeo.provincia && !ubigeo.departamento"
          ref="distrito"
          outlined
        />
      </v-col> -->
    </v-row>
    <v-btn
      color="primary"
      @click="validateDataForm"
      :disabled="!extraDataLoaded"
    >
      Siguiente
    </v-btn>
  </v-form>
</template>
<script>
import departamentos_json from './departamentos.json'
import provincias_json from './provincias.json'
import distritos_json from './distritos.json'
import axios from 'axios'

export default {
  data: () => ({
    docDataValid: false,
    extraDataLoaded: false,
    expire_menu: false,
    tipo_doc_items: [
      { text: 'Boleta', value: 1 },
      { text: 'Factura', value: 2 }
    ],
    serie_doc_items: [[{ text: '003', value: 3 }], [{ text: '003', value: 3 }]],
    doc_id_rules: [
      [v => (!!v && v.length === 8) || 'Ponga un DNI válido'],
      [v => (!!v && v.length === 11) || 'Ponga un RUC válido']
    ],
    doc_data_form: {
      tipo_doc: 1,
      serie_doc: 3,
      client_doc: '',
      client_data: '',
      client_direccion: '',
      departamentoText: '',
      provinciaText: '',
      distritoText: '',
      expire_at: new Date().toISOString().substr(0, 10)
    },
    api: {
      client_data: ''
    },
    ubigeo: {
      departamento: '',
      provincia: '',
      distrito: ''
    },
    departamentos: departamentos_json,
    provincias: provincias_json,
    distritos: distritos_json,
    clients: [],
    sellers: [],
    selected_client: {},
    selected_client_data: {},
    selected_address: {}
  }),
  watch: {
    'doc_data_form.tipo_doc': function(newVal) {
      console.log('new Val of tipo doc', newVal)
      if (this.api.client_data) {
        this.doc_data_form.client_doc = this.selected_client_data[
          this.tipoDocLabel.toLocaleLowerCase()
        ]
        // this.$refs.client_doc.reset()
        // this.$refs.client_name.reset()
        // this.$refs.client_dir.reset()
        // this.api.client_data = ''
        // this.extraDataLoaded = false
      }
    }
    // 'ubigeo.departamento': function() {
    //   if (this.api.client_data) {
    //     this.$refs.distrito.reset()
    //     this.$refs.provincia.reset()
    //     this.ubigeo.distrito = ''
    //     this.ubigeo.provincia = ''
    //   }
    // },
    // 'ubigeo.provincia': function() {
    //   if (this.api.client_data) {
    //     this.ubigeo.distrito = ''
    //     this.$refs.distrito.reset()
    //   }
    // }
  },
  computed: {
    tipoDocLabel() {
      if (this.doc_data_form.tipo_doc === 1) {
        return 'DNI'
      } else if (this.doc_data_form.tipo_doc === 2) {
        return 'RUC'
      } else {
        return '?????'
      }
    },
    clientText: {
      get: function() {
        return this.api.client_data.name

        // DEPRECATED BECAUSE OF SELF SEARCH OF CLIENTS
        // if (this.tipoDocLabel === 'DNI') {
        //   return `${this.api.client_data.nombres} ${this.api.client_data.apellidoPaterno} ${this.api.client_data.apellidoMaterno}`
        // } else {
        //   return this.api.client_data.razonSocial
        // }
      },
      set: function() {}
    }
  },
  mounted: function() {
    console.log('Mounted Data Stage')
    this.getSellers()
    this.getClients()
  },
  methods: {
    getFullAddress(item) {
      console.log('address', item)
      return `${item.value} (${item.departamento} - ${item.provincia} - ${item.distrito})`
    },
    validateDataForm() {
      if (this.$refs.docDataForm.validate()) {
        this.doc_data_form.client_name = this.clientText

        this.doc_data_form.departamentoText = this.selected_address.departamento
        this.doc_data_form.provinciaText = this.selected_address.provincia
        this.doc_data_form.distritoText = this.selected_address.distrito
        this.doc_data_form.client_direccion = this.selected_address.value
        console.log(this.ubigeo)
        this.$emit('dataStageCompleted', this.doc_data_form)
        console.log(this.doc_data_form)
      } else {
        this.$emit('dataStageFailed')
      }
    },
    goPrev() {
      this.$emit('prevStage')
    },
    searchJson(json, text) {
      for (let json_elem in json) {
        if (json[json_elem].nombre_ubigeo == text) {
          return json_elem
        }
      }
      return -1
    },
    filterObject(item, queryText) {
      return (
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1 ||
        (item.ruc &&
          item.ruc.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
            -1) ||
        (item.dni &&
          item.dni.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
            -1)
      )
    },
    async getClients() {
      let req_data = await this.$makeGetRequest('/api/clients')

      this.clients = req_data.data.data
      // console.log(this.clients)
    },
    async expandClient() {
      this.extraDataLoaded = false

      if (!this.$refs.seller.validate()) {
        this.$showMessage('Seleccione un vendedor', 'info')
        return
      }

      if (!this.$refs.clientSearch.validate()) {
        this.$showMessage('Seleccione un cliente', 'info')
        return
      }

      try {
        let req_data = await this.$makeGetRequest(
          `/api/clients/${this.selected_client.id}/get`
        )

        this.selected_client_data = req_data.data.data

        this.selected_address = this.selected_client_data.addresses
          ? this.selected_client_data.addresses[0]
          : ''

        this.api.client_data = {}

        this.api.client_data.name =
          this.tipoDocLabel === 'DNI'
            ? this.selected_client_data.name
            : this.selected_client_data.name_aux

        this.api.client_data.name = this.selected_client_data.name

        this.doc_data_form.client_doc = this.selected_client_data[
          this.tipoDocLabel.toLocaleLowerCase()
        ]
      } catch (error) {
        console.log(error)
        this.ubigeo.departamento = ''
        this.ubigeo.provincia = ''
        this.ubigeo.distrito = ''
        this.doc_data_form.client_direccion = ''
        this.$showMessage(
          'Hubo un error al traer los datos del cliente',
          'error'
        )
      }

      if (!this.$refs.docDataForm.validate()) return
      console.log(
        'Data of selected client',
        this.selected_client_data.addresses[0]
      )

      // let dep_data = await this.departamentos.find(
      //   ele =>
      //     ele.nombre_ubigeo.toUpperCase() ==
      //     this.selected_client_data.addresses[0].departamento
      // )
      // console.log('Dep found', dep_data, this.provincias[dep_data.id_ubigeo])
      // let pro_data = await this.provincias[dep_data.id_ubigeo].find(
      //   ele =>
      //     ele.nombre_ubigeo.toUpperCase() ==
      //     this.selected_client_data.addresses[0].provincia
      // )
      // console.log('Pro found', pro_data)
      // let dis_data = await this.distritos[pro_data.id_ubigeo].find(
      //   ele =>
      //     ele.nombre_ubigeo.toUpperCase() ==
      //     this.selected_client_data.addresses[0].distrito
      // )
      // console.log('Dis found', dis_data)
      // this.ubigeo.departamento = await dep_data
      // this.ubigeo.provincia = await pro_data
      // this.ubigeo.distrito = await dis_data

      this.extraDataLoaded = true
    },
    async searchClient() {
      this.extraDataLoaded = false

      if (!this.$refs.docDataForm.validate()) return

      let url = `https://dniruc.apisperu.com/api/v1/${this.tipoDocLabel.toLowerCase()}/${
        this.doc_data_form.client_doc
      }?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImFsZXhhbmRlci5hcGF6YUByZW5hc2UuY29tLnBlIn0.g0WFHN3e3z3Szx9-_fYl1KHDy6XEWnlKsvpED0-ykaA`
      let req_data = await axios.get(url, {
        crossdomain: true,
        withCredentials: false
      })
      this.api.client_data = req_data.data
      if (this.tipoDocLabel === 'RUC') {
        try {
          let direccion = this.api.client_data.direccion.split(' ')
          let [departamento, provincia, distrito] = direccion.splice(-3)
          this.doc_data_form.client_direccion = direccion.join(' ')

          let dep_data = await this.departamentos.find(
            ele => ele.nombre_ubigeo.toUpperCase() == departamento
          )
          let pro_data = await this.provincias[dep_data.id_ubigeo].find(
            ele => ele.nombre_ubigeo.toUpperCase() == provincia
          )
          let dis_data = await this.distritos[pro_data.id_ubigeo].find(
            ele => ele.nombre_ubigeo.toUpperCase() == distrito
          )

          this.ubigeo.departamento = await dep_data
          this.ubigeo.provincia = await pro_data
          this.ubigeo.distrito = await dis_data

          console.log(dep_data, pro_data, dis_data)
        } catch (err) {
          console.log(err)
          this.ubigeo.departamento = ''
          this.ubigeo.provincia = ''
          this.ubigeo.distrito = ''
          this.doc_data_form.client_direccion = ''
          this.$showMessage(
            'El cliente no tiene registrado una dirección en SUNAT',
            'error'
          )
        }
        this.extraDataLoaded = true
      } else {
        this.ubigeo.departamento = ''
        this.ubigeo.provincia = ''
        this.ubigeo.distrito = ''
        this.doc_data_form.client_direccion = ''
        this.extraDataLoaded = true
      }
    },
    async getSellers() {
      try {
        let req_data = await this.$makeGetRequest('/api/sellers')
        this.sellers = req_data.data
      } catch (error) {
        this.$showMessage('No se pudieron cargar los vendedores', 'error')
      }
    }
  }
}
</script>
