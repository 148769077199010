var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"prodDataForm",model:{value:(_vm.prodDataValid),callback:function ($$v) {_vm.prodDataValid=$$v},expression:"prodDataValid"}},[_c('small',[_vm._v(" Nota: Si cambia de Almacén, tendrá que volver a ingresar los productos ")]),_c('v-row',{staticClass:"mt-1",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{ref:"priceList",attrs:{"placeholder":"Seleccione lista de precios","label":"Lista de precios","items":_vm.pricelists_items,"rules":[function (v) { return !!v || 'Seleccione una lista de precios'; }],"item-text":"name","item-value":"id","outlined":""},model:{value:(_vm.prod_data.price_list_id),callback:function ($$v) {_vm.$set(_vm.prod_data, "price_list_id", $$v)},expression:"prod_data.price_list_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{ref:"warehouse",attrs:{"placeholder":"Almacén","label":"Almacén","items":_vm.$warehouses,"rules":[function (v) { return !!v || 'Seleccione un Almacén'; }],"outlined":""},model:{value:(_vm.prod_data.warehouse_id),callback:function ($$v) {_vm.$set(_vm.prod_data, "warehouse_id", $$v)},expression:"prod_data.warehouse_id"}})],1),_c('v-col',{attrs:{"cols":"8","md":"4"}},[_c('v-autocomplete',{ref:"productSearch",attrs:{"items":_vm.finishedProducts,"placeholder":"Escriba el código o nombre de un producto","label":"Buscador de productos","item-text":"product.description","item-value":"id","return-object":"","loading":_vm.products_loading,"rules":[
          function (v) { return _vm.notEmptyObject(v) || 'Escriba el código o nombre de un producto'; }
        ],"filter":_vm.filterObject,"outlined":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.product.description)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(data.item.product.code)}})],1)]]}}]),model:{value:(_vm.selectedProduct),callback:function ($$v) {_vm.selectedProduct=$$v},expression:"selectedProduct"}})],1),_c('v-col',{staticClass:"mt-3",attrs:{"cols":"4","md":"2"}},[_c('v-btn',{attrs:{"color":"secondary","disabled":_vm.products_loading},on:{"click":_vm.addProduct}},[_c('span',{staticClass:"d-flex d-sm-none"},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('span',{staticClass:"d-none d-sm-block"},[_vm._v("Agregar")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{ref:"productsTable",staticClass:"elevation-3",attrs:{"headers":_vm.headers,"items":_vm.selectedProducts,"item-key":"id","items-per-page":_vm.ppr,"hide-default-footer":true,"disable-pagination":"","sort-by":"code","no-data-text":"No se seleccionaron productos","dense":_vm.selectedProducts.length > 8},scopedSlots:_vm._u([{key:"item.is_free",fn:function(ref){
        var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"color":"primary","ripple":false},model:{value:(item.is_free),callback:function ($$v) {_vm.$set(item, "is_free", $$v)},expression:"item.is_free"}})]}},{key:"item.unit_price",fn:function(ref){
        var item = ref.item;
return [_c('v-edit-dialog',{on:{"save":function($event){return _vm.saveEdit(item, 'unit_price', 'float')},"open":function($event){_vm.tempEdit = item.unit_price}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Precio unitario","single-line":""},model:{value:(_vm.tempEdit),callback:function ($$v) {_vm.tempEdit=$$v},expression:"tempEdit"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.unit_price)+" ")])]}},{key:"item.quantity",fn:function(ref){
        var item = ref.item;
return [_c('v-edit-dialog',{on:{"save":function($event){return _vm.saveEdit(item, 'quantity', 'int')},"open":function($event){_vm.tempEdit = item.quantity}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Cantidad","single-line":""},model:{value:(_vm.tempEdit),callback:function ($$v) {_vm.tempEdit=$$v},expression:"tempEdit"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.quantity)+" ")])]}},{key:"item.discount",fn:function(ref){
        var item = ref.item;
return [_c('v-edit-dialog',{on:{"save":function($event){return _vm.saveEdit(item, 'discount', 'float')},"open":function($event){_vm.tempEdit = item.discount}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Discount","single-line":""},model:{value:(_vm.tempEdit),callback:function ($$v) {_vm.tempEdit=$$v},expression:"tempEdit"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.discount)+" ")])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
        var index = ref.index;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"medium":"","color":"red darken-1"},on:{"click":function($event){return _vm.deleteProduct(item, index)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.total_price",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTotalPrice( item.unit_price, item.quantity, item.discount, item.is_free ))+" ")]}},{key:"item.code",fn:function(ref){
        var item = ref.item;
return [_c('span',{class:item.stock < item.quantity ? 'warning--text' : 'success--text'},[_vm._v(" "+_vm._s(item.code))])]}},{key:"no-data",fn:function(){return [_c('span',{staticClass:"error--text text--lighten-2"},[_vm._v(" No se seleccionaron productos ")])]},proxy:true}])})],1)],1),_c('v-row',{staticClass:"mb-1"},[_c('v-col',{staticClass:"text-h6",attrs:{"align":"right"}},[_vm._v(" Total: "+_vm._s(_vm.prod_data.currency === 'SOLES' ? 'S/.' : '$')+" "+_vm._s(_vm.current_ammount)+" ")])],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.goNext}},[_vm._v(" Siguiente ")]),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.goPrev}},[_vm._v(" Atras ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }