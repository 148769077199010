<template>
  <v-form v-model="prodDataValid" ref="prodDataForm">
    <small>
      Nota: Si cambia de Almacén, tendrá que volver a ingresar los productos
    </small>
    <v-row class="mt-1" justify="center">
      <v-col cols="12" md="3">
        <v-select
          placeholder="Seleccione lista de precios"
          label="Lista de precios"
          :items="pricelists_items"
          v-model="prod_data.price_list_id"
          :rules="[v => !!v || 'Seleccione una lista de precios']"
          ref="priceList"
          item-text="name"
          item-value="id"
          outlined
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          placeholder="Almacén"
          label="Almacén"
          v-model="prod_data.warehouse_id"
          :items="$warehouses"
          :rules="[v => !!v || 'Seleccione un Almacén']"
          ref="warehouse"
          outlined
        />
      </v-col>

      <v-col cols="8" md="4">
        <v-autocomplete
          v-model="selectedProduct"
          :items="finishedProducts"
          placeholder="Escriba el código o nombre de un producto"
          label="Buscador de productos"
          item-text="product.description"
          item-value="id"
          return-object
          :loading="products_loading"
          :rules="[
            v =>
              notEmptyObject(v) || 'Escriba el código o nombre de un producto'
          ]"
          :filter="filterObject"
          ref="productSearch"
          outlined
        >
          <template v-slot:item="data">
            <template>
              <v-list-item-content>
                <v-list-item-title
                  v-html="data.item.product.description"
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-html="data.item.product.code"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="4" md="2" class="mt-3">
        <v-btn
          color="secondary"
          @click="addProduct"
          :disabled="products_loading"
        >
          <span class="d-flex d-sm-none"> <v-icon>mdi-plus</v-icon></span>
          <span class="d-none d-sm-block">Agregar</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="selectedProducts"
          item-key="id"
          class="elevation-3"
          :items-per-page="ppr"
          ref="productsTable"
          :hide-default-footer="true"
          disable-pagination
          sort-by="code"
          no-data-text="No se seleccionaron productos"
          :dense="selectedProducts.length > 8"
        >
          <template v-slot:item.is_free="{ item }">
            <v-simple-checkbox
              color="primary"
              v-model="item.is_free"
              :ripple="false"
            ></v-simple-checkbox>
          </template>

          <template v-slot:item.unit_price="{ item }">
            <v-edit-dialog
              @save="saveEdit(item, 'unit_price', 'float')"
              @open="tempEdit = item.unit_price"
            >
              {{ item.unit_price }}
              <template v-slot:input>
                <v-text-field
                  v-model="tempEdit"
                  label="Precio unitario"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.quantity="{ item }">
            <v-edit-dialog
              @save="saveEdit(item, 'quantity', 'int')"
              @open="tempEdit = item.quantity"
            >
              {{ item.quantity }}

              <template v-slot:input>
                <v-text-field
                  v-model="tempEdit"
                  label="Cantidad"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.discount="{ item }">
            <v-edit-dialog
              @save="saveEdit(item, 'discount', 'float')"
              @open="tempEdit = item.discount"
            >
              {{ item.discount }}

              <template v-slot:input>
                <v-text-field
                  v-model="tempEdit"
                  label="Discount"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.actions="{ item, index }">
            <v-icon
              medium
              color="red darken-1"
              class="mr-2"
              @click="deleteProduct(item, index)"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:item.total_price="{ item }">
            {{
              getTotalPrice(
                item.unit_price,
                item.quantity,
                item.discount,
                item.is_free
              )
            }}
          </template>
          <template v-slot:item.code="{ item }">
            <span
              :class="
                item.stock < item.quantity ? 'warning--text' : 'success--text'
              "
            >
              {{ item.code }}</span
            >
          </template>
          <template v-slot:no-data>
            <span class="error--text text--lighten-2">
              No se seleccionaron productos
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row class="mb-1">
      <v-col align="right" class="text-h6">
        Total:
        {{ prod_data.currency === 'SOLES' ? 'S/.' : '$' }}
        {{ current_ammount }}
      </v-col>
    </v-row>
    <v-btn color="primary" @click="goNext">
      Siguiente
    </v-btn>
    <v-btn text @click="goPrev">
      Atras
    </v-btn>
  </v-form>
</template>
<script>
export default {
  data: () => ({
    pricelists_items: [],
    finishedProducts: [],
    selectedProduct: {},
    selectedProducts: [],
    productsPrices: [],
    ppr: 16,
    tempEdit: '',
    prodDataValid: true,
    prod_data: {
      currency: 'SOLES',
      price_list_id: '',
      warehouse_id: 1
    },
    numberRule: v => {
      if (!v.trim()) return true
      if (!isNaN(parseFloat(v))) return true
      if (!isNaN(parseInt(v))) return true
      return 'Tiene que ser un número'
    },
    products_loading: false,
    headers: [
      {
        text: 'Gratuito',
        align: 'center',
        value: 'is_free',
        sortable: false
      },
      {
        text: 'Código',
        align: 'start',
        sortable: true,
        value: 'code'
      },
      {
        text: 'Descripción',
        value: 'description',
        sortable: false,
        filtereable: false
      },
      {
        text: 'Stock',
        sortable: false,
        value: 'stock',
        align: 'center'
      },
      {
        text: 'Cantidad',
        sortable: false,
        value: 'quantity',
        align: 'center'
      },
      {
        text: 'Precio Unitario',
        filtereable: false,
        sortable: false,
        value: 'unit_price',
        align: 'center'
      },
      {
        text: 'Descuento (%)',
        filtereable: false,
        sortable: false,
        value: 'discount',
        align: 'center'
      },
      {
        text: 'Precio Total',
        filtereable: false,
        sortable: false,
        value: 'total_price',
        align: 'center'
      },
      { text: 'Acciones', value: 'actions', sortable: false, align: 'center' }
    ],
    validQuantity: true
  }),
  methods: {
    goPrev() {
      this.$emit('prevStage')
    },
    goNext() {
      let validate_result =
        this.$refs.priceList.validate() && this.$refs.warehouse.validate()
      let products_length = this.selectedProducts.length > 0

      if (validate_result && products_length) {
        this.prod_data.total_ammount = this.current_ammount
        this.$emit('prodStageCompleted', {
          prod_data: this.prod_data,
          products: this.selectedProducts
        })
      } else {
        if (!validate_result) {
          this.$emit('prodStageFailed', 'Faltan completar datos')
          return
        }
        if (!products_length)
          this.$emit('prodStageFailed', 'No se seleccionaron productos')
      }
    },
    filterObject(item, queryText) {
      return (
        item.product.description
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.product.code
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1
      )
    },
    isInt(value) {
      if (parseFloat(value) == parseInt(value) && !isNaN(value)) {
        return true
      } else {
        return false
      }
    },
    isFloat(value) {
      if (!isNaN(parseFloat(value)) && !isNaN(value)) {
        return true
      }
      return false
    },
    saveEdit(item, key, type) {
      console.log('Rule result', this.isInt(this.tempEdit))
      let validate_fn = type === 'int' ? this.isInt : this.isFloat
      if (validate_fn(this.tempEdit)) {
        if (key === 'quantity' && this.tempEdit > item.stock) {
          this.$showMessage(
            'No hay suficiente stock para este producto',
            'warning'
          )
        }
        if (key === 'discount' && (this.tempEdit < 0 || this.tempEdit > 100)) {
          this.$showMessage('El descuento debe estar entre 0 y 100', 'warning')
          return
        }
        item[key] = this.tempEdit
      } else {
        this.$showMessage('El valor no es un número válido', 'error')
      }
    },
    addProduct: async function() {
      if (!this.$refs.prodDataForm.validate()) return
      let product_in_array = await this.selectedProducts.find(
        product => product.id === this.selectedProduct.id
      )
      if (product_in_array) {
        return
      }
      let productPrice = this.productsPrices[this.selectedProduct.product.code]
      if (!productPrice) {
        productPrice = '2.50'
      }
      await this.selectedProducts.push({
        id: this.selectedProduct.id,
        code: this.selectedProduct.product.code,
        description: this.selectedProduct.product.description,
        stock: this.selectedProduct.stock,
        unit_price: productPrice,
        quantity: '1',
        discount: 0,
        is_free: false
      })

      if (this.selectedProduct.stock == 0) {
        this.$showMessage(
          `${this.selectedProduct.product.code} no tiene stock`,
          'warning'
        )
      }
      // await this.selectedProviderProducts.push(product_to_add)
      // console.log(this.selectedProviderProducts)
      // this.selectedProduct = {}
      this.$refs.productSearch.reset()
      this.ppr = 10
    },
    deleteProduct(item, index) {
      console.log('Delete', item, index)
      this.selectedProducts.splice(index, 1)
    }
  },
  mounted: async function() {
    this.$makeGetRequest('/api/pricelists').then(response => {
      this.pricelists_items = response.data.data
    })

    this.$makeGetRequest('/api/finishedproducts/warehouse/1').then(response => {
      this.finishedProducts = response.data.data
    })
  },
  computed: {
    current_ammount: {
      get() {
        let total_ammount = this.selectedProducts.reduce(
          (total, current_product) => {
            return (
              total +
              parseFloat(
                this.getTotalPrice(
                  current_product.unit_price,
                  current_product.quantity,
                  current_product.discount,
                  current_product.is_free
                )
              )
            )
          },
          0
        )
        console.log('Total ammount:', total_ammount)
        return total_ammount.toFixed(2)
      }
    }
  },
  watch: {
    'prod_data.warehouse_id': function(val) {
      this.products_loading = true
      this.$makeGetRequest(`/api/finishedproducts/warehouse/${val}`)
        .then(response => {
          this.finishedProducts = response.data.data
          this.products_loading = false
          this.selectedProducts.splice(0, this.selectedProducts.length)
          this.$showMessage('Productos reiniciados', 'info')
        })
        .catch(err => {
          this.$showMessage(
            'Hubo un problema al cargar los productos del Almacén ',
            'error'
          )
          console.log(err)
        })
    },
    'prod_data.price_list_id': function(val) {
      this.products_loading = true
      this.$makeGetRequest(`/api/pricelists/${val}/products`)
        .then(response => {
          this.productsPrices = response.data.data
          this.products_loading = false
          this.$showMessage('Lista de precios cargada', 'info')

          this.selectedProducts.forEach(product => {
            product.unit_price = this.productsPrices[product.code]
              ? this.productsPrices[product.code]
              : '2.50'
          })

          console.log('Precios:', this.productsPrices)
        })
        .catch(err => {
          this.$showMessage(
            'Hubo un problema al cargar la lista de precios',
            'error'
          )
          console.log(err)
        })
    }
  }
}
</script>
